// import logo from "../public/build-famas.png";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img
          src={require("./images/build-famas.png")}
          // className="App-logo"
          alt="logo"
        />
        <p>Página en construcción.</p>
      </header>
    </div>
  );
}

export default App;
